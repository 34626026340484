/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;
function restoreWishlistButtonState() {
    $(".addWishlistBtn").html(button_add_to_wishlist_initial_label);
    $(".addWishlistBtn")
        .removeClass("existToWishlistButton")
        .addClass("addToWishlistButton");
    $(".addWishlistBtn")
        .attr("onclick", button_add_to_wishlist_initial_click_event)
        .attr("href", "#");
}

/**
 * Checks if a product is in the wishlist.
 * 
 * @param {number} productId - The ID of the product.
 * @param {string} productRef - The reference of the product.
 * @param {boolean} enableBtnWishlist - Flag to enable the wishlist button.
 */
function checkProductInWishlist(productId, productRef, enableBtnWishlist = false) {
    var isProductPage = $("#produit_id").val();
    var colorId = $("#couleurProd_" + productId).val();
    var sizeId = $("#tailleProd_" + productId).val();
    if (isProductPage > 0) {
        productId = $("#produit_id").val();
        colorId = $("#couleurProd").val();
        sizeId = $("#tailleProd").val();
	}
    if (typeof colorId == "undefined") {
        colorId = 0;
    }
    if (typeof sizeId == "undefined") {
        sizeId = 0;
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_wishlist"),
            data:{'product_id': productId, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);

                var wishlistButton = $("#addToWishlistButton_" + productId);
                if (isProductPage > 0) {
                    wishlistButton = $("#addToWishlistButton");
                }

                if (typeof userWishlist !== undefined && userWishlist.length > 0) {
                    updateButtonWishlist(productId);
                    changeBtnAddWishlist(true, productRef, enableBtnWishlist);
                    $("#addToWishlistButton").attr("data-wishlistproductid", userWishlist[0].wishlist_product_id);
                } else {
                    changeBtnAddWishlist(false, productRef, enableBtnWishlist);
                    addToWishlistUpdateHeaderCount();
                }
            }
        });
    }
}

$(document).ready(function() {
    var id = $("#produit_id").val();
    var red = $(".prodColor:checked").attr("id");
    checkProductInWishlist(id, red);
});

/**
 * Updates the wishlist button based on the provided productId.
 * Adds the class "existToWishlistButton" to the wishlist button with matching data-productid attribute,
 * and removes the class "existToWishlistButton" from other wishlist buttons.
 *
 * @param {string} productId - The ID of the product to update the wishlist button for.
 */
function updateButtonWishlist(productId) {
    $("a.wishlistBtn").each(function() {
        if ($(this).attr("data-productid") == productId) {
            $(this).addClass("existToWishlistButton");
        } else {
            $(this).removeClass("existToWishlistButton");
        }
    });
}

/**
 * Changes the behavior and appearance of the "Add to Wishlist" button based on the provided parameters.
 *
 * @param {boolean} in_wishlist - Indicates whether the product is already in the wishlist.
 * @param {string} productRef - The reference of the product.
 * @param {boolean} [enableBtnWishlist=true] - Specifies whether the wishlist button should be enabled.
 */
function changeBtnAddWishlist(in_wishlist, productRef, enableBtnWishlist = true) {

    var btn = $("#addToWishlistButton");

    // Default onclick Add or Remove wishlist
    var onclickAddToWishlist = "addToWishlist.call(this);";

    // Handle if product is in wishlist or not
    if (in_wishlist) {
        // Handle wishlist for achat express v1, onclick go to my wishlist page in another tab
        if ($("#lightbox_achat_express").hasClass("actif")) {
            onclickAddToWishlist = "window.open('" + path_relative_root + create_link("wishlist") + "', '_blank');";
        }
        btn.val(Translator.translate("exist_to_wishlist_button_label"));
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.val(Translator.translate("add_to_wishlist"));
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
        //remove wishlist icon on basket hover
        $("#order_basket_list a[data-product-ref="+productRef+"]").removeClass("existToWishlistButton").addClass("addToWishlistButton");        
    }

    // Activate btn
    if (enableBtnWishlist == true) {
        btn.attr("disabled", false);
    }
    btn.attr("onclick", onclickAddToWishlist);
}

/**
 * Redirects the user to the login page with a specific query parameter.
 */
function wishlistRedirectLogin() {
    $.get(
        path_relative_root + create_link("ajax_add_to_wishlist") + '?login_required=1',
        function() {
            document.location.href = path_relative_root + create_link("connexion_login") +"?from=front";
        }
    );
}

/**
 * Updates the wishlist header with the given number and performs additional actions based on the number.
 * @param {number} nr - The number to update the wishlist header with.
 */
function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html(nr);

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html("0");
    } else {
        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html(nr);
    }

    var response_html = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
    $("#wishlist_top").html(response_html).addClass('active_rollover');

    if ($('.product_mention_number').length && $('.product_mention_number').html().length == 0 && nr > 0) {
        $('.product_mention_number').html(nr);
    }

    // HEADER SCROLLBARS
    if ($('#wishlist_list').length) {
        $('#wishlist_list').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    if (typeof triggerShad !== undefined){
        triggerShad('menu', 'add');
    }
}

/**
 * Updates the header count and wishlist content.
 * @function addToWishlistUpdateHeaderCount
 */
function addToWishlistUpdateHeaderCount() {
    var response_html = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
    $("#wishlist_top").html(response_html);
    // HEADER SCROLLBARS
    if ($('#wishlist_list').length) {
        $('#wishlist_list').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }
}

/**
 * Adds a product to the wishlist.
 *
 * Wishlist V2 + Update the number of products in the wishlist
 *
 * @param {boolean} selected - Indicates if a size is already selected for the product.
 * @param {number} productId - The ID of the product to add to the wishlist.
 * @param {number} [sizeId=0] - The ID of the size for the product. Defaults to 0 if not provided.
 * @param {Array} [formProduct=[]] - The form data for the product. Defaults to an empty array if not provided.
 */
function addToWishlistV2(selected = false, productId, sizeId = 0, formProduct = []) {
    var btn = (productId != undefined) ? $('#addToWishlistButton_' + productId)[0] : this;
    var idElt = btn.getAttribute('data-idelt');
    var productId = btn.getAttribute('data-productid');
    var wishlist = [];
    var form = (typeof this.form !== 'undefined') ? this.form : formProduct;

    if (typeof (productId) == 'undefined') {
        productId = btn.getAttribute('data-productid');
    }

    var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    sizeId = (sizeId == 0) ? $('#tailleProdW_' + idElt).val() != '' ? $('#tailleProdW_' + idElt).val() : $('#tailleProdW').val() : sizeId;

    if ((!colorId || colorId == undefined) || (!sizeId || sizeId == undefined)) {
        return false;
    }

    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'false',
        need_size_id: 'false',
    };

    // If the heart is not filled and we have a default size, we enter the condition (e.g. 5five with only one size)
    if (!btn.classList.contains('existToWishlistButton') && sizeId !== undefined) {

        $.ajax({
            url: path_relative_root + create_link('ajax_add_to_wishlist'),
            type: 'post',
            data: datas
        })
        .done(function (resp) {

            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox");
            $alertboxLogin.addClass('box_wishlist');

            if (resp.error) {

                if (resp.message == "login_required") {
                    var html = '<div class="box_wishlist">' + resp.result.error_message + '<div class="btn_container">' + '<button class="button dark" onclick="wishlistRedirectLogin();">' + resp.result.btn_connexion + "</button>" + "</div>" + "</div>";
                    $('#shad').addClass('actif');

                    if ($('#lightbox_achat_express').length > 0) {
                        $('#lightbox_achat_express').fadeOut("fast", function () {
                            $('#shad').css('display', 'none');
                        });
                    } else {
                        openModalBox.call(document.getElementById('alertboxlogin'));
                        $('.wishlist_ray').hide();
                    }

                    $("#abox .form_submit").css("display", "none");
                } else if (resp.result.error_message) {
                    alert(translate(resp.result.error_message));
                } else {
                    var message = resp.result.error_message ? resp.result.error_message : resp.message;
                    $(".disabledBtnTooltip").addClass('actif');
                    $(".wrap_btn_abox button span").html('ok');
                    if ($('.category').length > 0) {
                        alert(Translator.translate('error_wishlist_' + message.toLowerCase()));
                    }
                    return false;
                }
            } else {
                // If selected = true, it means we don't need to select a size as it is already selected
                if (selected) {
                    if (parseInt($('#in_basket').val()) === 1) {
                        var btn_wish = "#addToWishlistButton_" + datas.product_id;
                        var span_wish = btn_wish + " span";
                        var del_product = "#del_product_" + datas.product_id;

                        btn_wish = btn_wish.replace(',', '');
                        span_wish = span_wish.replace(',', '');
                        del_product = del_product.replace(',', '');

                        $(span_wish).html(resp.result.button_label);
                        $(btn_wish).attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));
                        addToWishlistUpdateHeader(resp.result.wishlist_size);
                        $(del_product)[0].click();
                    } else {
                        $("#menu_account_wishlist").css({'background':'red'});
                        $("#addToWishlistButton span").html(resp.result.button_label);
                        $("#addToWishlistButton").attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));
                        $("#shade").removeClass("visible").removeClass("isExpress");
                        btn.setAttribute("data-wishlistproductid", resp.result.wishlist_product_id);

                        btn.classList.add("existToWishlistButton");
                        wishlist.push(resp.result.wishlist_row_array);
                        addToWishlistUpdateHeader(resp.result.wishlist_size);

                        // The product is in the wishlist, we handle the display of the button
                        btn.textContent = Translator.translate("exist_to_wishlist_button_label");
                        // Display the wishlist in the menu and update the number of added products
                        $('ul.list_menu #menu_account_wishlist').css('display', 'block');
                        $('ul.list_menu #menu_account_wishlist span.highlight').text('(' + resp.result.wishlist_size + ')');
                        $("#menu_account_wishlist").css({'background':'red'});
                    }
                    $('.wishlist_ray').css('display', 'none');
                } else {
                    // Need to specify the size to add to the wishlist
                    openSizeWishlistV2.call(form);
                    btn.classList.add("existToWishlistButton");
                }
            }
        });
    } else {
        // If sizeId is undefined and the button does not have the class existToWishlistButton, we open the size selection
        if (!btn.classList.contains("existToWishlistButton")) {
            openSizeWishlistV2.call(form);
            // Check if selected is false and if we have the class existToWishlistButton to remove the product from the wishlist
        } else if (btn.classList.contains("existToWishlistButton")) { console.log("removing from wishlist");
            btn.classList.remove("existToWishlistButton");
            ajax_RemoveWishlistProd(btn);
            // Reset the sizeProdW of the product to empty, in order to be able to display the size selection if it is not initially defined
            if (!selected) {
                $('#tailleProdW_' + idElt).val("");
            }
        }
    }
}

/**
 * Scans for changes in the wishlist and updates the total price, total points, 
 * and selected products accordingly. It also updates the UI to reflect these changes.
 *
 * The function iterates over all elements with the class `checkbox_select_product`, 
 * checks if they are selected, and updates the total price, total points, and 
 * selected products list. It also updates the HTML to display the total price 
 * and the number of selected products.
 *
 * @global
 * @function
 * @name scanForChanges
 * @returns {void}
 */
function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();
    wishlistProductData = [];
    selectedProductsObj = {};
    $(".checkbox_select_product").each(function() {
        var element = $(this);
        var id = element.attr("data-id");

        // Selects the .product_ctn block
        var productCnt = $(this).closest('.product_ctn');

        if (element.is(":checked")) {
            var price = parseFloat(element.attr("data-price"));

            if (price) {
                totalPrice += price;
            }

			wishlistProductData.push({
                productId: element.attr("data-produit-id"),
                colorId: element.attr("data-color-id"),
                sizeId: element.attr("data-size-id"),
            });

            selectedProductsObj.selectedProducts = JSON.stringify(wishlistProductData);

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }

            // add class checked
            productCnt.addClass('checked');
        } else {
            // remove class checked
            if (productCnt.hasClass('checked')) {
                productCnt.removeClass('checked');
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + " :";
            priceSpan.html('<span id="total_price">0.<span class="decimal_price">00</span><span class="devise">&euro;</span></span>');
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == "") text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = "";
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = "" + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + "0";
                }
            }

            priceSpan.html(
                '<span id="total_price">' +
                    pricefloor +
                    '.<span class="decimal_price">' +
                    decimal +
                    "</span><span class=\"devise\">&euro;</span></span>"
            );
            txt =
                '<span class="wishlist_count">' +
                selectedProducts.length +
                "</span> " +
                '<span class="wishlist_count_wording">' +
                text +
                " :</span>";
    }

    $(".txt", "#nrArticlesSelected").html(txt);
}

/**
 * Adds a product to the cart.
 * 
 * @param {string} wpId - The ID of the wishlist product.
 * @param {string} pId - The ID of the product.
 * @param {string} sId - The ID of the size.
 * @param {string} cId - The ID of the color.
 */
function addProductToCart(wpId, pId, sId, cId) {
    $.post(
        path_relative_root + create_link("ajax_wishlist_add_product_to_cart"),
        {
            product_id: pId,
            size_id: sId,
            color_id: cId,
        },
        function(resp) {
            if (resp == "ok") {
                document.location.href =
                    path_relative_root + create_link("order_basket");
            } else {
                if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                    alert(Translator.translate("err_no_points"));
                }
            }
        }
    );
}

/**
 * Ferme une popup par rapport à son nom et ferme aussi le shad
 * Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php
 */
function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = "shad";
    }
    var $popup = $("#" + popup_id),
        $shad = $("#" + shad_id);

    $shad.unbind("click.popup");
    $popup.slideUp("slow", function() {
        $shad.fadeOut("slow", function() {
            if ($("#wrapper_sendwishlist").hasClass("sended")) {
                $("#sendwishlistbox").remove();
            }
        });
    });
}

/**
 * Displays the send wishlist modal.
 */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

/**
 * Sends the wishlist form data via AJAX.
 * 
 * @returns {boolean} Returns false to prevent the default form submission.
 */
function sendWishListForm() {
    $("#sendWishListSubmit").css({
        display: "none"
    });
    $("#wrapper_loader_send").css({
        display: "block"
    });
    var content_id = $("#send_wishlist_ajax");
    var content;
    if (
        $("#sendWishListToMails").length &&
        $("#sendWishListToMessage").length &&
        $("#sendWishListYourName").length &&
        $("#sendWishListYourMail").length
    ) {
        var to_mails = $("#sendWishListToMails").val();
        var to_message = $("#sendWishListToMessage").val();
        var your_name = $("#sendWishListYourName").val();
        var your_mail = $("#sendWishListYourMail").val();
        var token_security = $("#token_security_wishlist").val();
        var captcha = $("#g-recaptcha-response").val();

        $.ajax({
            url: path_relative_root + create_link("ajax_send_wishlist_form") +
                "?to_mails=" + to_mails +
                "&to_message=" + to_message +
                "&your_name=" + your_name +
                "&your_mail=" + your_mail +
                "&token_security=" + token_security +
                "&g-recaptcha-response=" + captcha
        }).done(function(response) {
            $(".zone_text").css({
                border: ""
            });
            if (response.split(",")[0] == "ok") {
                var s_adresse = response.substr(3);
                var sing = response.split(",").length > 2 ?
                    "sendfriend_ok_plur" :
                    "sendfriend_ok_sing";

                content = "<div>" + Translator.translate(sing) + "</div>";
                content += '<p style="margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;">' + s_adresse + "</p>";
            }
            if (response == "1" || response == "2") {
                content = '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_mail_friends_invalid') + "</p>";
                $("#sendWishListToMails").addClass("inputErr");
            } else {
                $("#sendWishListToMails").removeClass("inputErr");
            }
            if (response == "3") {
                content = '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_message') + "</p>";
                $("#sendWishListToMessage").addClass("inputErr");
            } else {
                $("#sendWishListToMessage").removeClass("inputErr");
            }
            if (response == "4") {
                content =
                    '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_name') +
                    "</p>";
                $("#sendWishListYourName").addClass("inputErr");
            } else {
                $("#sendWishListYourName").removeClass("inputErr");
            }
            if (response == "5" || response == "6") {
                $("#sendWishListYourMail").addClass("inputErr");

                if (response == "5") {
                    content =
                        '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_my_mail') +
                        "</p>";
                } else {
                    content =
                        '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_my_mail_invalid') +
                        "</p>";
                }
            } else {
                $("#sendWishListYourMail").removeClass("inputErr");
            }

            if (response == "token_error") {
                content =
                    '<p class="txt_err_sendfriend">' + Translator.translate('error_token_security') + '</p>';
            }

            if (response.split(",")[0] == "7") {
                var s_adresse = response.substr(3);
                var sing =
                    response.split(",").length > 2 ?
                    "sendfriend_no_ok_plur" :
                    "sendfriend_no_ok_sing";
                content =
                    "<div>" + Translator.translate(sing) + "</div>";
            }
            if (
                response.split(",")[0] == "7" ||
                response.split(",")[0] == "token_error" ||
                response.split(",")[0] == "ok"
            ) {
                content_id = $("#sendfriend_alert_mail");

                $("#sendfriend_alert_mail").css({
                    display: "block",
                    opacity: 0
                });
                $("#wrapper_sendwishlist")
                    .addClass("sended")
                    .css({
                        display: "block",
                        opacity: 1
                    })
                    .animate({
                        opacity: 0
                    }, function() {
                        $("#wrapper_sendwishlist").css({
                            display: "none"
                        });
                        $("#sendfriend_alert_mail").animate({
                                opacity: 1
                            },
                            function() {
                                $("#sendfriend_alert_mail").css({
                                    display: "block"
                                });
                            }
                        );
                    });
            } else {
                $("#sendfriend_form_buttons").removeClass("loading");
            }

            content_id.html(content).css({
                display: "block"
            });
        });
    }

    return false;
}

/**
 * Loads the selected size based on the response received from the server.
 *
 * @param {number} cnt_sizes - The number of sizes available.
 */
function loadSelectedSize(cnt_sizes) {

    var sizes = document.getElementsByName('itm_size');

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link("ajax_size_selected"),
        success: function (response) {
            if (response && response.length > 0) {
                response = $.parseJSON(response);
                var selected_size = (response.selected_size);
                var current_size;
                var current_element;
                var sizeProd = document.getElementById("tailleProd");
                var html = '';

                for (var i = 0; i < sizes.length; i++) {
                    current_element = sizes.item(i).value;
                    if ((selected_size === current_element && current_element !== null) && (!sizes.item(i).classList.contains("disabled"))) {
                        sizes.item(i).checked = true;
                        sizeProd.value = current_element;
                        enableAddCart();
                    }
                }
            }
        }
    });
}

/**
 * Moves a product from the wishlist to the cart.
 *
 * @param {Element|boolean} elemBtn - The button element that triggered the move, or false if no button was clicked.
 * @param {Object} selectedProducts - An object containing the selected products.
 */
function moveProductFromWishlistToCart(elemBtn = false, selectedProducts = {}) {

    let datas = {};

    if (elemBtn) {
        let prodId = $(elemBtn).attr("data-produit-id");
        let colorId = $(elemBtn).attr("data-color-id");
        let sizeId = $(elemBtn).attr("data-size-id");

        datas.data = $(elemBtn).attr("data-id");

        if (prodId !== undefined) {
            datas.productId = prodId;
        }

        if (colorId !== undefined) {
            datas.colorId = colorId;
        }

        if (sizeId !== undefined) {
            datas.sizeId = sizeId;
        }
    } else {
        datas = selectedProducts;
    }

    $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), datas, function(resp) {
        if (resp === "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

/**
 * Allows you to add a product to the wishlist via the ray (or search)
 * app/_components/product/achat_express_search.php
 * app/_components/product/achat_express.php
 * If user is connect, display wishlist lightbox (achat express wishlist = true)
 * if not, call the product page addToWishlist function which already display a connect lightbox
 */
function addToWishlistRay(user) {
    addToWishlist.call(this);
}

/**
 * Adds a product to the wishlist.
 */
function addToWishlist() {

    var btn = this;
    var idElt = btn.getAttribute('data-idelt');
    var productId = btn.getAttribute('data-productid');
    var wishlistProductId = btn.getAttribute('data-wishlistproductid');
    var productRef = btn.getAttribute('data-product-ref');
    let isMobile = $('body').hasClass('mobile');

    var wishlist = [];

    if (typeof productId == 'undefined' || productId == null) {
        productId = $("#hdn_product_id").val();
    }

    if (isMobile) {
        var colorId = $('#prod_couleur .color[selected=selected]').val();
        var sizeId = $('#prod_taille').val();
        $('#prod_taille').removeClass('error');
        if (!sizeId) {
            $('#prod_taille').addClass('error');
            return false;
        }
    } else {
        var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();

        if (typeof colorId === 'undefined') {
            colorId = (btn.getAttribute('data-colorid')!=null) ? btn.getAttribute('data-colorid') : btn.getAttribute('data-product-color-id');
        }

        var sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();

        if (typeof sizeId == 'undefined') {
            sizeId = (btn.getAttribute('data-sizeid')!=null) ? btn.getAttribute('data-sizeid') : btn.getAttribute('data-product-size-id');
        }
    }

    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'false',
        need_size_id: 'false'
    };

    if (!btn.classList.contains('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + create_link('ajax_add_to_wishlist'),
            type: 'post',
            data: datas
        })
        .done(function (resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox");
            $alertboxLogin.addClass('box_wishlist');

            if (resp.error) {
                if (resp.message == "login_required") {
                    var html =
                        '<div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="btn_container">' +
                        '<button class="button dark" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</button>" +
                        "</div>" +
                        "</div>";
                    $('#shad').addClass('actif');
                    if ($('#lightbox_achat_express').length > 0) {
                        $('#lightbox_achat_express').fadeOut("fast", function () {
                            $('#shad').css('display', 'none');
                        });
                    }
                    alert(html);
                    $("#abox .form_submit").css("display", "none");
                } else if (resp.result.error_message) {
                    alert(Translator.translate(resp.result.error_message));
                } else {
                    var message = resp.result.error_message ? resp.result.error_message : resp.message;
                    $(".disabledBtnTooltip").addClass('actif');
                    $(".wrap_btn_abox button span").html('ok');
                    if ($('.category').length > 0) {
                        alert(Translator.translate('error_wishlist_' + message.toLowerCase()));
                    }
                    return false;
                }
            } else {
                if (parseInt($('#in_basket').val()) === 1) {
                    var btn_wish = "#addToWishlistButton_" + productId;
                    var span_wish = btn_wish + " span";
                    var del_product = "#del_product_" + productId;

                    btn_wish = btn_wish.replace(',', '');
                    span_wish = span_wish.replace(',', '');
                    del_product = del_product.replace(',', '');

                    $(span_wish).html(resp.result.button_label);
                    $(btn_wish).attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    $(del_product)[0].click();
                } else {
                    $("#addToWishlistButton span").html(resp.result.button_label);

                    btn.setAttribute("data-wishlistproductid", resp.result.wishlist_product_id);
                    btn.classList.add("existToWishlistButton");

                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);

                    changeBtnAddWishlist(true, productRef);

                    // Display the wishlist in the menu and update the data for the number of added products
                    $('ul.list_menu #menu_account_wishlist').css('display', 'block');
                    $('ul.list_menu #menu_account_wishlist span.highlight').text('(' + resp.result.wishlist_size + ')');

                    $("#menu_account_wishlist").css({'background':'pink'});

                    //Allow to keep the heart filled for products in the wishlist when another product is moved to basket
                    setTimeout(function () { 
                        getWishlistProducts(); 
                    }, 1200);
                }
            }
        });
    } else {
        datas.id = (wishlistProductId != '' && wishlistProductId != undefined) ? wishlistProductId : productId;
        $.ajax({
            url: path_relative_root + create_link('ajax_remove_from_wishlist'),
            type : 'post',
            data: datas
        })
        .done(function(resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox");
            $alertboxLogin.addClass('box_wishlist');
            if (!resp.error) {
                if ($('body').hasClass('mobile')) {
                    // Check if item is already in wishlist
                    btn.classList.remove("existToWishlistButton");
                    btn.setAttribute('data-wishlistproductid', '');
                    // MOBILE - update wishlist item count
                    $('ul.list_menu #menu_account_wishlist').css('display', 'block');
                    $('ul.list_menu #menu_account_wishlist span.highlight').text('(' + resp.result.wishlistLength + ')');
                } else {
                    // DESKTOP - update wishlist lightbox
                    btn.classList.remove("existToWishlistButton");
                    btn.setAttribute('data-wishlistproductid', '');
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    changeBtnAddWishlist(false, productRef);
                }
            }
        });
    }
}

/**
 * Removes a product from the wishlist.
 *
 * @param {Object} datas - The data to be sent in the request.
 * @param {Function} callback - The callback function to be executed after the request is complete.
 */
function remove_product_from_wishlist(datas, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), datas, callback);
}

/**
 * Removes a product from the wishlist using AJAX.
 * @param {HTMLElement} elemBtn - The button element that triggered the removal.
 */
function ajax_RemoveWishlistProd(elemBtn) {

    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    if (typeof colorId === 'undefined') {
        colorId = ($(elemBtn).attr('data-colorid')!=null) ? $(elemBtn).attr('data-colorid') : $(elemBtn).attr('data-product-color-id');
    }

    if (typeof prodId === 'undefined') {
        prodId = ($(elemBtn).attr('data-produit-id')!=null) ? $(elemBtn).attr('data-produit-id') : $(elemBtn).attr('data-productid');
    }

    if (typeof sizeId === 'undefined') {
        sizeId = ($(elemBtn).attr('data-size-id')!=null) ? $(elemBtn).attr('data-size-id') : $(elemBtn).attr('data-product-size-id');
    }

    let datas = {
        id: $(elemBtn).attr("data-id"),
        remove: true
    };

    if (prodId !== undefined) {
        datas.product_id = prodId;
    }

    if (colorId !== undefined) {
        datas.color_id = colorId;
    }

    if (sizeId !== undefined) {
        datas.size_id = sizeId;
    }

    remove_product_from_wishlist(datas, function(data) {
        if (data.result) {
            $('.product-link[data-i=' + prodId + ']').remove();
            let wishlistLength = data.result.wishlistLength;
            if (wishlistLength < 1) {
                if ($('#show_top_wish .rollover_empty').length) {
                    $('#show_top_wish .rollover_empty').remove()
                } else {
                    $('#wishlist_top .title_panier').remove();
                    $('#wishlist_top .wrap_tot_panier').remove();
                    $('#wishlist_top #wishlist_list').remove();
                    $('#wishlist_top .title_panier .title_wishlist_rollover .nb_products').remove();
                    $('#wishlist_top .wishlist .product_mention_number').remove();
                }
                $('#wishlist_top #show_top_wish').addClass('noprod');
                if ($("#show_top_wish.noprod>.wrap_title").length) {
                    $("#show_top_wish.noprod>.wrap_title").remove();
                }
                var newtxt = '<div class="rollover_empty"><div class="title_panier title">'+ Translator.translate('my_wishlist') +'</div><div class="no_products">' + Translator.translate('your_wishlist_empty') + '</div></div>';
                $('#wishlist_top #show_top_wish').append(newtxt);
            } else {
                $('#wishlist_top .title_panier .title_wishlist_rollover .nb_products').text('(' + (wishlistLength));
                $("#wishlist_top .cart_product_listing").overlayScrollbars({});

                // MOBILE updating the number of products in wishlist when a product is removed from wishlist
                $('ul.list_menu #menu_account_wishlist').css('display', 'block');
                $('ul.list_menu #menu_account_wishlist span.highlight').text('(' + wishlistLength + ')');

                $("#menu_account_wishlist").css({'background':'green'});
                // Update wishlist header and the wishlist lightbox
                addToWishlistUpdateHeaderCount();
            }
        }
    });
}