$(function() {

    var siteHeader = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        if (!document.body.classList.contains("in_tunnel")) {
            var scrollTop = $(document).scrollTop();

            if (siteHeader.classList.contains("sticky")) {
                if (scrollTop === 0) {
                    siteHeader.classList.remove("sticky");

                    // Add / remove sticky to algolia search container
                    if (document.querySelector('.ais-InstantSearch')) {
                        if (document.querySelector('.ais-InstantSearch').classList.contains("sticky")) {
                            document.querySelector('.ais-InstantSearch').classList.remove("sticky");
                        }
                    }
                    shade.classList.remove("sticky");
                }
            } else {
                    if (scrollTop > ($('#site_head_wrap').outerHeight() + 100)) {
                    siteHeader.classList.add("sticky");

                    // Add / remove sticky to algolia search container
                    if (document.querySelector('.ais-InstantSearch')) {
                        if (!document.querySelector('.ais-InstantSearch').classList.contains("sticky")) {
                            document.querySelector('.ais-InstantSearch').classList.add("sticky");
                        }
                    }
                    shade.classList.add("sticky");
                }
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    $(window).on("scroll", onWinScroll);

    onWinScroll();

    $(document).on("click", ".button", btnLoaderTrigger);


    $(document).on("focus", "#clientTel", formCoFieldTelFix);
    $(document).on("focusout", "#clientTel", formCoFieldTelFixOut);

    /* Filter rayon */

    $(document).on("click", ".filter_checkbox_hide", function() {
        var currentClass = $(this).attr("class");
        $(".filter_checkbox_hide").each(function (index) {
            if(currentClass != $(this).attr("class")) {
                $(this).prop('checked', false);
            }
        });
    });

    /* Smooth animation for all anchor */

    var $root = $('html, body');

    $('a[href^="#"]').click(function () {
        if ($(this).attr("href").length > 1) {
            var scrollPos = $( $.attr(this, 'href') ).offset().top;
            scrollPos = scrollPos - 120;
            $root.animate({
                scrollTop: scrollPos
            }, 500);

            return false;
        }
    });

    $('.shipment_cgv_link').click(function () {
        $(this).toggleClass('change').attr('data-content','bar');
    });

    /* Addings in TUNNEL *******************************************************/
    if ($(".step_2 #cart_total .bill_line.total_produit_dropdown:visible").length || $(".checkout.in_tunnel #cart_total .bill_line.total_produit_dropdown:visible").length || $(".step_2_0 #cart_total .bill_line.total_produit_dropdown:visible").length) {
        $('#cart_total .bill_line.total_produit_dropdown').hide();
        $( ".bill_line.total_produit.toggle" ).after( "<div onclick='seeMoreProd()' class='seeMore bgSprite aft rightarrow'><p>Voir tout</p></div>" );
    }
    /********************************************************************************************* */

    $('#form_contact1 .w-submit-button').click(function () {
        var lastName = $('input[name="contactPrenom"]').val();
        var name = $('input[name="contactNom"]').val();
        var fullName = lastName + '&nbsp;' + name;
        setTimeout(function () {
            if ($('#ligthbox_send_store_information #form_contact1:visible').length && $('#ligthbox_send_store_information #form_contact1 .w-input-error:visible').length === 0) {
                $("#ligthbox_send_store_information #contact_store").css("display", "none");
                $("#ligthbox_send_store_information #contact_store").after(Translator.translate('mail_wording_sent', fullName));
            } else if ($('.choose_fav_mag.showForm:visible').length && $('.choose_fav_mag.showForm .w-input-error:visible').length === 0) {
                $(".choose_fav_mag.showForm #contact_store").css("display", "none");
                $(".choose_fav_mag.showForm #contact_store" ).after(Translator.translate('mail_wording_sent', fullName));
            }
        }, 1200);
    });
});


function seeMoreProd() {
    $(".bill_line.total_produit.toggle").click();
}

document.onload=function(){

    if ($("#slider-range").length) {
        var prix_min = $("#amount1").val();
        var prix_max = $("#amount2").val();
        $("#amount1").val(prix_min);
        $("#amount2").val(prix_max);
        $("#slider-range").slider("option", {
            min: parseInt(prix_min),
            max: parseInt(prix_max),
            values: [parseInt(prix_min), parseInt(prix_max)],
    });

        $(".ui-slider-range").width("100%");
        $(".ui-slider-range").css("left", "0%");
        $(".ui-slider-range")
            .next()
            .css("left", "0%");
        $(".ui-slider-range")
            .next()
            .next()
            .css("left", "100%");
    }
}

function formCoFieldTelFix() {
    $( "#clientTel" ).parent().children("p.w-input-label").css("transform", "translateY(-115%)");
    $( "#clientTel" ).parent().children("p.w-input-label").css("font-size", ".75rem");
    $( "#clientTel" ).parent().children("p.w-input-label").css("transition", "transform .2s ease-out,font-size .2s ease-out,opacity .2s linear");
}

function formCoFieldTelFixOut() {
    if ($('#clientTel').val() == '') {
        $( "#clientTel" ).parent().children("p.w-input-label").css("transform", "translateY(-50%)");
        $( "#clientTel" ).parent().children("p.w-input-label").css("font-size", "13px");
    }
}

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/**
 * Buttons loader management
 */
function btnLoaderTrigger(evt) {
	"use strict";

	var element = $(this);
	var loader = $(this).next(".loader");

	if (loader.length > 0) {

		evt.preventDefault();

		loader.removeAttr("style").parent().addClass("loading");
		loader.removeClass("cache");
		loader.addClass("loading");

		setTimeout(function () {
			loader.parent().removeClass("loading");
			loader.addClass("cache");
			loader.removeClass("loading");
		}, 1200);

		if ($(this).attr("href")) {
			window.location.assign($(this).attr("href"));
		}
	}
}

function openMultiShad(id) {
    if($('.lightbox.actif').length) {
        $('.lightbox.actif').removeClass('actif');
    }

    $("#" + id).addClass('actif');

    if (id !== 'zoomBox') {
        if (!$("#shad").hasClass('actif')) {
            $("#shad").addClass('actif');
        }
        $("#shad").on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id == "lightbox_achat_express") {
        var prod_id,
        form,
        prod_qty,
        prod_rr_id,
        prod_is_wish;

        prod_id = $('#lightbox_achat_express #produit_id').val();
    }

    if (id === "zoomBox") {

        $('#zoom_box_wrapper .img_large').on('click', function () {

            closeMultiShad('zoomBox');
        });
    }

    if (id === 'popup_savoir_plus') {
        $("#popup_savoir_plus").css("display", "block");
        $('#shad').css('display', 'block');
    }

    if (id === 'lightboxOrderGiftCard' || id === 'sendwishlistbox') {
        $('#shad').addClass('actif');
    }
}

function closeMultiShad(id) {
    $('body').css('position','');
    $("#" + id).removeClass('actif');
    $("#shad").removeClass('actif');
    $(".shad").removeClass('actif').removeClass('white');

    if (id === 'exit_overlay_OVI') {
        $('#exit_overlay_OVI').hide();
        $("#shad").hide();
    }

    if(id === 'popup_savoir_plus') {
        $("#popup_savoir_plus").hide();
        $('#shad').hide();
    }
}

function changeLocale(locale, uri, site) {
	$.ajax({
		url: path_relative_root + create_link('ajax_get_uri_by_lang'),
		type: 'post',
		data: {
			lang: locale,
			url: uri,
			site : site
		},
		success: function (data) {
			if (data != "") {
				var force = "";
				if (uri == "/") {
					force = "?force";
				}
				location.href = data + force;
			}
		}
	});
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Reload product from ref
/**
 * loadProduct function
 * @param new_id
 * @param type
 * @param page
 * @param itm_rr_id
 * @param has_stock
 * @param dateArrivageJJA
 * @param triggerType : new param to determine from where is triggered the loadProduct function (colorChange, sizeChange, null(-> FP))
 * @param is_regroup
 */

function loadProduct(new_id, type, page, itm_rr_id, triggerType, is_regroup = true) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var cpt = this.getValue('cpt');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 0;
    triggerType = (triggerType !== undefined) ? triggerType : '';

    //Get original width and height of changed item, preserving style
    var oldModel = $(this).attr('id') !== '' ? $(this).attr('id').replace('prod_info_', '#itm-') : '',
        newModel = oldModel !== '' ? oldModel.replace('_', '-') : '',
        newWidth = $(newModel).css('width');

    if(this.elements.namedItem('qteProd')  ) {
        qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : is_wishlist ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data : data,
        beforeSend : function () {

        },
        success : function (res) {
            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var prod_assoc = $('#assocs_slider', $html_main);
                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var info_wrapper = $('.product_info_wrapper', $html_main);
                    var zoom_box_wrapper = $('#zoom_box_wrapper', $html_main);
                    var zoom_thumb_wrapper = $('.zoom_main_wrapper .thumb_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        if (is_regroup) {
                            // Photo et information
                            var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                            $('#itm-' + itm_id).replaceWith($html_main);

                            var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                            var margin_right = $('#itm-' + new_itm_id).next().css('margin-right');
                            $('input.color_' + new_id + itm_rr_id).prop('checked', false);

                            $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);

                            initAchatExpressSwipers();
                        }
                        getWishlistProducts();

                        // 5FIVE SPECIAL CASE INTEGRATION : Once you have selected a color, in achat_express_v2 --> Display sizes
                        if (triggerType === 'colorChange') {
                            // Hide colors if visible
                            if ($("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible')) {
                                $("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).fadeOut(600);
                            }
                            // Display sizes right after
                            setTimeout(function() {
                                $("#ligne_pointure_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).fadeIn(600);
                            }, 600)
                        } else if (triggerType === 'sizeChange') {
                            // On size change -> be sure the product is available
                            $('#tailleProd_' + (new_id + itm_rr_id)).val(sze_id);

                            // En fonction de la couleur on regarde si le produit est disponible -> si getAvailableQuantity renvoi true, on ajoute au panier
                            if (getAvailableQuantity(new_id, qte_itm, 'product', data.couleurProd, itm_rr_id)) {
                                waitingAddToBasket(null,  document.getElementById('prod_info_' + new_id + itm_rr_id), '', itm_rr_id);
                            } else {
                                // If getAvailableQuantity renvoi false == no stock, on affiche l'alerte stock;
                                if ($("#ligne_pointure_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible')) {
                                    $("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible').fadeOut(600);
                                }

                                // If no stock, show alert stock and hide add to basket btn
                                $("#bloc_add_color_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).hide();
                                $("#bloc_add_alert_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).show();
                            }
                        }
                    } else if (is_wishlist) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                    } else {
                        var $html_middle = $(stripCombo(datas.html_middle));

                        $('.item_price_wrapper', $html_main).next('div').after($html_middle).remove();

                        var contents = $('<div />').html($html_main);

                        var video = $('.wrapper_video', contents);
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        $('.product_main_wrapper', '.product_info_wrapper').html(main_wrapper.html());

                        if (prod_assoc.length > 0) {
                            $('#assocs_slider', '#site_global_wrap').after(prod_assoc).remove();
                        }

                        // depending on triggerType, update either color checked or size selected
                        if (triggerType == 'sizeChange') {
                            $('#tailleProd .size').prop('selected', false);
                            $('#tailleProd option#size_'+data.tailleProd).prop('selected', true);
                        }

                        if(triggerType == 'colorChange') {
                            $('input.color').prop('checked', false);
                            $('input#color_'+data.couleurProd).prop('checked', true);
                        }

                        $('#videoBox', '#site_global_wrap').remove();
                        $('#shade').after(video);
                        if (document.getElementById("alma-widget") !== null) {
                            initAlmaWidget();
                        }

                        $('.breadcrumb.current').html('<span>' + datas.product.titreobjet + '</span>');

                        //zoom box
                        $('#zoom_box_wrapper').html(zoom_box_wrapper.html());
                        $('.zoom_main_wrapper .thumb_wrapper').html(zoom_thumb_wrapper.html());

                        getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id);
                    }

                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                if (typeof $('#' + old_id + '-' + cpt) !== "undefined") {
                    $('#' + old_id + '-' + cpt).attr("id", datas.product.id + "-" + cpt);
                }

                checkProductInWishlist(datas.product.id, itm_rr_id);

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                $('.thumbslide .see_more').on('click', function(e) {
                    $('.thumbslide').removeClass('active');
                    $(this).parents('.thumbslide').addClass('active');
                });

            }else{
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {
            var heads = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            //Simulates scroll (2px) to load images
            if(!$('.product_page').length){
                window.scrollTo(0, $(window).scrollTop()+2);
            }

            setTimeout(function() {
                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();
            }, 250);

            //Swiper TG home et produits associés: pas de chargement du swiper, ajout de la class swiper-slide pour l'inté
            //Permet de conserver l'inté du swiper
            if($(".homepage").length){

                if($('.swiperTg').length){
                    var model = $('.swiperTg').find($('.swiper-slide'));
                } else if ($('.alt_sld_view').length){
                    var model = $('.alt_sld_view');
                };

                var item = $('.item:not(.swiper-slide)'),
                    newItm = '#itm-' + new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                item.css({
                    width: $(model).css('width'),
                    height: $(model).find('imgProd').css('height')
                })

                $(item).find($('.swiper-slide-off')).css('width', '100%');
                item.addClass('swiper-slide');

            }

            //Swiper consultés récemment sur page produit : chargement du lazyload au changement de couleur pour afficher l'image
            if($(".product_page").length){
                var model;
                var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                /* MAIN PRODUCT SWIPER + THUMBS */

                var mainSlideFp = $("#jqzoom");
                var thumbSlideFp = $("#visual_scroller");

                if(thumbSlideFp.find(".swiper-wrapper>.swiper-slide").length >= 4) {
                    var productThumbs = new Swiper('#visual_scroller', {
                        slidesPerView: 4,
                        spaceBetween: 40,
                        direction: 'vertical',
                        loop: false,
                        navigation: {
                            nextEl: '.thumbNext',
                            prevEl: '.thumbPrev',
                        },
                        threshold: 4,
                    });
                } else {
                    $("#visual_scroller_wrapper").addClass('no_swiper');
                }

                if(mainSlideFp.find(".swiper-wrapper>.swiper-slide").length > 1) {
                    //animation to avoid slide's width transition on pageload
                    $('#jqzoom').animate({
                        opacity: 1,
                    },10);
                    var main_productVisu = new Swiper('#jqzoom', {
                        loadOnTransitionStart: true,
                        thumbs: {
                            swiper: productThumbs
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            type: "bullets",
                            clickable: true
                        },
                        slidesPerView: 1,
                        loop: false,
                    });
                }

                resetThumbSwiperFP();

                /* PRODUITS ASSOCIES */
                // Produits complementaires *********************************************
                if (($('#complementary_product').length)) {
                    if ($("#gondole_aimerez .swiper-wrapper>.swiper-slide").length > 4) {
                        if ($('.complementary_product_pagi').length && !$('.complementary_product_pagi').is(':visible')) {
                            $('.complementary_product_pagi').show();
                        }
                        var productThumbsaa = new Swiper('#gondole_aimerez', {
                            loop: false,
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 10,
                            navigation: {
                                nextEl: '.swiper-button-nexta',
                                prevEl: '.swiper-button-preva',
                            },
                        });
                    } else {
                        $("#gondole_aimerez").addClass('no_swiper');

                        if ($('.complementary_product_pagi').length) {
                            $('.complementary_product_pagi').hide();
                        }
                    }
                }

                // Produits similaires *********************************************
                if($("#gondole_complete .swiper-wrapper>.swiper-slide").length > 4) {

                    if ($('.similar_product_pagi').length && !$('.similar_product_pagi').is(':visible')) {
                        $('.similar_product_pagi').show();
                    }
                    var productThumbsa = new Swiper('#gondole_complete', {
                        spaceBetween: 10,
                        loop: false,
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    });
                } else {
                    $("#gondole_complete").addClass('no_swiper');

                    if ($('.similar_product_pagi').length) {
                        $('.similar_product_pagi').hide();
                    }
                }



                /* PRODUITS RECEMMENT CONSULTES */
                if ($('#productVisitedSwiper .swiper-wrapper>.assoc').length > 5) {
                    var productThumbsaa = new Swiper('#productVisitedSwiper', {
                        spaceBetween: 5,
                        loop: false,
                        slidesPerView: 5,
                        navigation: {
                            nextEl: '#recent_see .swiper-button-next',
                            prevEl: '#recent_see .swiper-button-prev',
                        },
                        scrollbar: {
                            el: '#recent_see .swiper-scrollbar',
                        },
                        pagination: {
                            el: '#recent_see .swiper-pagination',
                            type: 'progressbar',
                        },
                    });
                }
                setTimeout(function(){
                $('#itm-' + new_itm_id).children('.imgProd').css({'opacity':'1'});
                }, 1000);
            }

            $(document).ready(function() {

                setTimeout(function(){

                    $(window).on('load resize', function () {

                        var img_max_height = $('body.homepage .main_wrapper#site_global_wrap .home-module.simple-gondole .home-column .productVisualMulti').width();
                        $('body.homepage .main_wrapper#site_global_wrap .home-module.simple-gondole .home-column .productVisualMulti').css('max-height', img_max_height);

                        var video_url_link = $('.visited_pdt_holder .productVisualMulti .productBigPictures iframe').width();
                        $('.visited_pdt_holder .productVisualMulti .productBigPictures .video_url').css('min-height', video_url_link);

                        var video_recemment = $('.visited_pdt_holder .swiper-slide .block_lnk iframe').width();

                        $('.mainSlideFp iframe.otherViewImg').height( $('.mainSlideFp iframe.otherViewImg').width() );
                        $('.productVisualMulti.productSwiper iframe').height( $('.productVisualMulti.productSwiper iframe').width());

                        $('.visited_pdt_holder .swiper-slide .block_lnk iframe').css( $('max-height', video_recemment));

                    }).trigger('load resize');
                }, 1000);

            });
        }
    });
}


function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(translate('js_error_couleur'));
        return;
    }

    if ($('#bloc_add_alert_form').is(':visible') && !$('.bloc_add_alert_confirmation').is(':visible')) {
        $('#bloc_add_alert_form').slideUp('fast', function () {
            $('.bloc_add_alert_erreur').hide();
        });
    } else if ($('#bloc_add_alert_form').is(':visible') && $('.bloc_add_alert_confirmation').is(':visible')) {
        $('#bloc_add_alert_form').slideUp('fast', function () {
            $('.bloc_add_alert_confirmation').hide();
            $('.bloc_add_alert_erreur').hide();
        });
    } else if (!$('#bloc_add_alert_form').is(':visible') && $('.bloc_add_alert_confirmation').is(':visible')) {
        $('#bloc_add_alert_form').slideUp('fast', function () {
            $('.bloc_add_alert_confirmation').hide();
        });
    } else {
        $('#btn_add_alert').hide();
        $('#bloc_add_alert_form').slideDown('fast');
    }

}

function loadcolor() {
    $("body.product_page").on("click", "#more_coloris", function(){
        $("body.product_page .choices_list").addClass("show_all");
        $("body.product_page #more_coloris").hide();
    });
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);
    // Loader
    btn.parent('.form_submit').addClass('loading');
    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;
    var form = document.getElementById('prod_info' + elem);
    var color_id = form.elements['couleurProd'].value;
    var size_id = form.elements['tailleProd'].value;
    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    var tailleProdCrossSell = $('#qteProd_' + product_id + regroup_ref_id);

    var sizeQnt = form.elements['cnt_sizes'] ? form.elements['cnt_sizes'].value :  $('#cnt_sizes' + product_id + '_' + regroup_ref_id);
    var colorQnt = form.elements['cnt_colors'] ? form.elements['cnt_colors'].value :  $('#cnt_colors_' + product_id + '_' + regroup_ref_id);
    if (colorQnt > 1 && regroup_ref_id === '') {
        $( ".form_itm.color input[name='itm_color']" ).each(function( index ) {
            $(this).prop( "checked", false );
        });
        $("#ligne_couleur_" + product_id + regroup_ref_id).fadeIn(600);
    } else if (colorQnt < 2 && sizeQnt > 1) {
        // Here we only have one color and several sizes, be sure to check availability when size selected
        $("#ligne_pointure_" + product_id + regroup_ref_id).fadeIn(600);
    } else {
        if (($('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) || ($('#tailleProd_' + product_id + '_' + regroup_ref_id).value == '0') || (typeof tailleProdCrossSell[0] != "undefined" && tailleProdCrossSell[0].value == "0")) {
            $(this).parent().hide();
            $('#bloc_add_alert' + elem).show();
        } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
            // On regarde si la personne a sélectionenr la taille et la couleur
            if (color_id !== '' && size_id !== '') {
                waitingAddToBasket(event, form, '', regroup_ref_id);
                setTimeout(function() {
                    btn.parent('.form_submit').removeClass('loading');
                }, 2000);
            } else if (color_id === '' && size_id === '') {
                alert(translate('choose_a_size_and_a_color'));
                // Loader
                btn.parent('.form_submit').removeClass('loading');
            } else if (color_id === '') {
                alert(translate('choose_a_color'));
                // Loader
                btn.parent('.form_submit').removeClass('loading');
            } else if (size_id === '') {
                alert(translate('choose_a_size'));
                // Loader
                btn.parent('.form_submit').removeClass('loading');
            }
        }
    }

    //Allow to keep the heart filled for products in the wishlist when another product is moved to basket
    setTimeout(function () { 
        getWishlistProducts(); 
    }, 1200);
}

// Called upon size change in product page
function onItemSizeChange(form, is_regroup, itm_rr_id) {

    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from  = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (isAchatExpressV2) {
        if (this.checked) {
            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'changeSize',
                    'produit': {
                        'size_value': ($(this).attr('data-nom')),
                    }
                });
                if (window.dataLayer[0].product) {
                    window.dataLayer[0].product.size = ($(this).attr('data-nom'));
                }
            }

            if ($('#sizeerror').is(':visible')) {
                $('#sizeerror').slideUp();
            }

            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');

            // Depending on the color we see if the product is available, do this only in product page
            // We check if the product is already in the wishlist
            checkProductInWishlist(productId, productRef, true);
        }
    } else {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }
        if (is_regroup) {
            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');
        }

        // DO NOT DELETE COMMENT IN CASE EVO REGROUP NOT DONE *************************************************************************************
        // Depending on the color we see if the product is available, do this only in product page
        // We check if the product is already in the wishlist
        checkProductInWishlist(productId, productRef, true);
    }
}

/**
 * @description Called upon color change in product page
 */
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var control, prod_id, from_page;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();

        // If it is achat_express_v2
        if (is_achat_express_v2 && ($('[name="itm_id_' + control.value + itm_rr_id + '"]').length > 1)) {
            // Find the input_check checked and look at your parent to find the right name=itm_id_.. inside as there are more than one
            var checkedColor_input = $(form).find('.input_check:checked');
            var checkedColor_value = checkedColor_input.parent().find('[name="itm_id_' + control.value + itm_rr_id + '"]').val();
            prod_id = checkedColor_value;
        } else {
            prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        }

        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (is_assoc_product == true) {
            from_page = 'assoc';
        } else {
            from_page = 'product';
        }

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id, 'colorChange');
        } else {
            // Check if the product is already in the wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist(prod_id, itm_rr_id);
            } else {
                loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id, 'colorChange', false);
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // Define sizes that still have stock
            setAvailableSizes.call(this);
        }
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, idCouleur, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var list = $('.qte_select_wrapper', form).get(0);
    var plnb = form.getValue('produit_lot_nb');
    var pcol = form.getValue('idCouleur_' + id + '_' + itm_rr_id);
    var qty = (qty !== undefined) ? qty : 1;
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;

    id = ( (id !== undefined) ? id : '' );

    if (typeof(pcol)=='undefined') {
        var pcol = form.getValue('idCouleur');
    }

    var val_color = form.getValue(pcol);
    if (val_color == '0') {
        val_color = 'none';
    }

    var psze = form.getValue('idTaille');
    var val_size = form.getValue(psze);
    if (val_size == '0' && !(plnb > 0)) {
        val_size = 'none';
    }

    if (idCouleur !== undefined) {
        val_color = idCouleur;
        $('#'+pcol).val(idCouleur);
        $( "#color_"+idCouleur ).attr( "checked", "checked" );
    }

    var data = {
        idprod : form.getValue('produit_id'),
        idcouleur : val_color,
        idtaille : val_size,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    var product_has_stock;

    $.ajax({
        url : path_relative_root + create_link('ajax_liste_quantity'),
        type : 'get',
        async : false, // Should be async but would then need a loader so...
        data : data,
        dataType: 'json',
        success : function (res) {
            var new_select;
            if (list) {
                list.innerHTML = res.content.trim();

                if (qty) {
                    new_select = form.elements.namedItem('qteProd');
                    if ($('[value="' + qty + '"]', new_select).length > 0) {
                        new_select.value = qty;
                    } else {
                        new_select.value = $('option:last', new_select).val();
                    }
                }
            }

            if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
                $("#delivery_delay").hide();
            } else {
                $("#delivery_delay").show();
                $("#date_du").html($("#date_du_ajax").val());
                $("#date_au").html($("#date_au_ajax").val());
            }

            if ($('.txt_reason_borne').length > 0) {
                $('.info_magasin').html($('.txt_reason_borne').html());
                $('.info_magasin').show();
            }

            $('#liste_qte').html(res.content);
            $(".eclatProd").show();
            $(".precoDeliveryDate").show();

            var regexp_epuise = /Stock /;
            var txt_stock = $('#qteProd>option:selected').text().match(regexp_epuise) != null;

            if (!is_achat_express) {
                if ($('#case_ajax') !== 'undefined') {
                    if ($('#case_ajax').val() == '5' || $('#case_ajax').val() == '6' || $('#case_ajax').val() == '7') {
                        $(".eclat_nouveaute").removeClass('cache').show();
                        $(".eclat_epuise").hide();
                        $(".eclatProd").show().removeClass('cache');

                        $("#bloc_add_alert").removeClass('cache').show();
                        $("#bloc_add_basket").hide();
                        $(".stock_info.yes").hide();
                        $(".stock_info.no").show();
                        $(".productQuantityFieldset").addClass("without_stock");
                        $(".qte_select_wrapper").addClass("without_stock");
                        $(".chez_vous_entre").hide();
                    } else if ($('#case_ajax').val() == '8') {
                        $(".eclat_epuise").removeClass('cache').show();
                        $(".eclat_nouveaute").hide();
                        $(".eclatProd").hide();

                        $("#bloc_add_basket").hide();
                        $("#bloc_add_alert").hide();
                        $(".stock_info.yes").hide();
                        $(".stock_info.no").show();
                        $(".productQuantityFieldset").addClass("without_stock");
                        $(".qte_select_wrapper").addClass("without_stock");
                        $(".chez_vous_entre").hide();
                    } else { // Case 1 / 2 / 3 / 4
                        $(".eclat_epuise").hide();
                        $(".eclat_nouveaute").hide();
                        $(".eclatProd").show().removeClass('cache');
                        $("#bloc_add_basket").show();
                        $("#btn_add_cart").attr('disabled', false);
                        $("#bloc_add_alert").hide();
                        $(".stock_info.yes").show();
                        $(".stock_info.no").hide();
                        $(".productQuantityFieldset").removeClass("without_stock");
                        $(".qte_select_wrapper").removeClass("without_stock");
                        $(".chez_vous_entre").show();

                        // DATALAYER V2
                        pushIntoDatalayer(res.dataLayer_object);
                    }
                }
            } else {
                if (res.stock_tampon == 0) {
                    if (wShop.$refs.wAlertestockForm) {
                        wShop.$refs.wAlertestockForm.reloadBloc += 1;
                    }

                    // DATALAYER V2
                    pushIntoDatalayer(res.dataLayer_object);
                    product_has_stock = false;
                } else {
                    // DATALAYER V2
                    pushIntoDatalayer(res.dataLayer_object);
                    product_has_stock = true;
                }
            }
        }
    });

    if (is_achat_express) {
        return product_has_stock;
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express) {
        evt.preventDefault();
    }

    if (from === undefined) {
        from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        loader = $(this.nextElementSibling);
        loader.addClass("loading");
    } else {
        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }

    //Allow to keep the heart filled for products in the wishlist when another product is moved to basket
    setTimeout(function () { 
        getWishlistProducts(); 
    }, 1000);
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id)
{
    var id = '';
    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;
        if (is_not_produit == true) {
            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';
        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd';
        } else {
            omnitureEvent = 'scAdd';
        }

        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            pushIntoDatalayer(array_panier[5]);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        $("#cart_top .cart_product_listing").overlayScrollbars({});

        newshowmodal(produit_id, is_not_produit);
    } else {
        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

// Alters item quantity in cart
function chanQteList() {
    var item = this.getValue('item_id', parseFloat);
    var loader = document.getElementById('qte_loader_' + item);

    var old_qte = parseInt($(this).find('.selected_qte').val());
    var new_qte = parseInt($(this).find('.qnt_prod').val());
    var rank = $(this).index('.qte_selector');
    this.qtty_select_wrap.style.display = 'none';
    loader.style.display = 'block';

    if (window.dataLayer !== undefined) {
        var product = productFieldObject[rank];

        if (new_qte > old_qte) {
            product.quantity = (new_qte - old_qte);
            dataLayer.push({
                "event": "addToCart",
                "ecommerce": {
                    'currencyCode': siteCurrency.code,
                    "add": {
                        "products": [product]
                    }
                }
            });
        } else if (new_qte < old_qte) {
            product.quantity = (old_qte - new_qte);
            dataLayer.push({
                "event": "removeFromCart",
                "ecommerce": {
                    "remove": {
                        "products": [product]
                    }
                }
            });
        }
    }

    $.ajax({
        url : path_relative_root + create_link("order_basket"),
        type : 'post',
        data : {
            action : 'del',
            panierId : item,
            cad  : 'non',
            pointcad : 0,
            qteProd : new_qte,
            change_qte : true
        },
        cache : false,
        success : function (res) {
            try {
                res = JSON.parse(res);
            } catch (e) {
                console.error('JSON ERROR: could not parse data');
                return false;
            }

            res = $('<div />').html(res.datas);

            document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
            if (wShop.$refs.wCouponForm) {
                wShop.$refs.wCouponForm.reloadBloc+=1;
            }

            // Cart slider initialization
            var sliderCart = $('#main_cart_wrapper #basketAssocWrapper');

            if (sliderCart.length) {
                sliderCart.remove();
            }

            //update top cart
            var response = ajax_file(create_link('ajax_show_basket'));
            document.getElementById('cart_top').innerHTML = response;

        },
        complete : function() {
            var total = $('#cart_top .link_item_cart').attr('data-cart'),
            cart = Translator.translate('mon_panier'),
            text;
            if(total <= 1){
                text = Translator.translate('one_product');
            } else {
                text = Translator.translate('2_product');
            };

            //Simulates scroll (2px) to load images
            setTimeout(function() {
                window.scrollTo(0, $(window).scrollTop()+3);
            }, 100);

            getWishlistProducts();
        }
    });
}

/**
 * Tunnel FAQ
 */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') {
            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {
        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {
        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function newshowmodal(products, type, root, id_produit, is_not_produit)
{
    topCartAppear();

    window.setTimeout(function () {
        topCartDesappear();
    }, 2000);
}

function topCartAppear() {
    var cart_top = $('#cart_top');
    cart_top.addClass('active_rollover')

    $('#show_top_cart #order_basket_list').overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });

    $('#shad_menu').addClass("actif");
}

function topCartDesappear() {
    var cart_top = $('#cart_top');

    cart_top.removeClass('active_rollover')
    $('#shad_menu').removeClass("actif");
}

/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {
        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + create_link('livraison-ajax'),
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.error(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};
function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function(elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function() {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

function closeAlerteStock(elem) {
    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

/**
 * Permet de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @return  void
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;
        var has_stock = false;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];
                if (is_disabled) {
                    size_box.addClass('disabled');
                } else {
                    size_box.removeClass('disabled');
                    has_stock = true;
                }
            }
        }

        if (has_stock) {
            $("#btn_add_cart").show();
            $('.eclat_nouveaute').hide();
            $(".eclat_epuise").hide();
        } else {
            if ($('#case_ajax') !== 'undefined') {
                if ($('#case_ajax').val() == '5' || $('#case_ajax').val() == '6' || $('#case_ajax').val() == '7') {
                    $(".eclat_nouveaute").removeClass('cache').show();
                    $(".eclat_epuise").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#bloc_add_alert").removeClass('cache').show();
                    $("#btn_add_cart").hide();
                } else if ($('#case_ajax').val() == '8') {
                    $(".eclat_epuise").removeClass('cache').show();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").hide();

                    $("#btn_add_cart").hide();
                    $("#bloc_add_alert").hide();
                } else { // Case 1 / 2 / 3 / 4
                    $(".eclat_epuise").hide();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#btn_add_cart").removeClass('cache').show();
                    $("#bloc_add_alert").hide();
                }
            }
        }
    }
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function(){loader.removeAttr("style").parent().removeClass("loading")}, 200);
    }
}

// FERMER EN SAVOIR PLUS CONNEXION

function close_popup(elem) {
    var $modbox = $("#" + elem);
    $("#" + elem).removeClass('actif');
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
}

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                            fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

// popup PDV
function modalStorePref(is_find_store) {
    var shad, modbox, contents;

    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $('html, body').css({
        overflow: 'hidden',
        height: '100%'
    });

    shad = document.getElementById('shad');
    modbox = document.getElementById('pdvBox');
    $(shad).addClass("actif");
    $(modbox).slideDown(600);
    $(shad).on('click', closepdv);

    $(".storeloc_form.lightbox_storeloc_form").attr('autocomplete', 'none');

    setTimeout(function () {
        $("#search_cp").attr('autocomplete', 'none');
    }, 1000);

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax-distributeurs'),
        success: function (res) {

            if (res) {

                shad = document.getElementById('shad');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);
                $('#pdvBox').removeClass('deployed');

                // Make sure modbox will show on top of shad
                $(shad).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).html($('.storeloc_form', contents).add($('.liste_distributeur_wrapper', contents)));

                var listeMag = document.getElementById('content_store_locator');
                listeMag.innerHTML = '';

                //wording
                if (is_find_store) {
                    $('.popup_title').html(Translator.translate('find_in_store'));
                    $('.popup_intro').html(Translator.translate('lightbox_infos_horaires_covid'));
                    //Ajout-suppression de classe sur le conteneur pour différencier les css entre : le trouver en magasin/Choisir magasin préféré
                    $('#pdvBox').addClass('find_art_in_store');
                    $('#pdvBox').removeClass('choose_fav_mag');
                } else {
                    $('.popup_title').html(Translator.translate('store_pref_title'));
                    $('.popup_intro').html(Translator.translate('lightbox_infos_horaires_covid'));
                    $('#pdvBox').addClass('choose_fav_mag');
                    $('#pdvBox').removeClass('find_art_in_store');
                }

                // On va rajouter le filtre pour n'avoir que les magasin avec le comptoir pour les PDV
                // On doit le rajouter en JS car on utilise la même vue avec la recherche magasin
                if (!document.getElementById('filter_pdv')) {
                    var caratactestiques = [];

                    if (document.getElementById('produit_id')) {
                        var productId = document.getElementById('produit_id').value;
                    }
                    if (document.getElementById('produit_caracterstiques')) {
                        var is_caracteristique = document.getElementById('produit_caracterstiques');
                    }

                    if ((typeof (is_caracteristique) != 'undefined') && (typeof (is_caracteristique.value) != 'undefined')) {
                        var product_caracteristique = document.getElementById('produit_caracterstiques').value;
                        //On récupère la carateristique du produit comptoir-atmo si elle existe et on applique le filtre sur le produit
                        if (product_caracteristique == "comptoir-atmo") {
                            var filter = $('<input />', {
                                type: 'checkbox',
                                name: 'filtres[]',
                                id: 'filter_pdv',
                                value: 'comptoir-atmo',
                                checked: 'checked'
                            });
                            $('.storeloc_form', modbox).append(filter);
                        }
                    }
                }

                // magasin_gmap.js
                initialize(true);

                // Show modbox and make sure closing routine won't fire more than once
                $(shad).off('click', closepdv).fadeIn(200, function () {

                    $(modbox).slideDown(600);
                    $(shad).on('click', closepdv);
                    $('#content_pdvBox_new').hide();
                    $('#content_pdvBox').show();
                    $(modbox).removeClass('showForm');
                    $(modbox).removeClass('thanks');
                });
            }
        }
    });
}


// Page Rayon

function filterTrigger(event) {
    if($('#filter_sticky').length) {
        var trigger = $('#filter_sticky');
        var filter = $('#trigger_filtre');
        var shad = $('#shad_link');
        var header = $('#site_head_wrap');

        if(!trigger.hasClass('triggered')) {
            $(event.target).addClass("is_checked");
            if ($('#filter_sticky .filters_container').length) {
                $('#filter_sticky .filters_container_wrapper').overlayScrollbars({});
            }
            trigger.addClass('triggered');
            filter.addClass('show');
            header.addClass('filters_in');
            shad.addClass('actif filterOn');

            var wichFilter = $(event.target).attr("id");
            if ($("."+wichFilter).length) {
                $( ".filters_dropdown_bloc input[type='checkbox']" ).each(function( index ) {
                    $(this).prop( "checked", false );
                });

                $("."+wichFilter).prop('checked', true);
            }

            var wichFilter = $(event.target).attr("id");
            if ($("."+wichFilter).length) {
                $( ".filters_dropdown_bloc input[type='checkbox']" ).each(function( index ) {
                    $(this).prop( "checked", false );
                });

                $("."+wichFilter).prop('checked', true);
            }

        } else if(trigger.hasClass('triggered')) {
            $( ".filters_dropdown_content input[type='checkbox']" ).each(function( index ) {
                $(this).prop( "checked", false );
            });
            $( ".filtre_elem" ).each(function( index ) {
                $(this).removeClass( "is_checked" );
            });
            $(event.target).addClass("is_checked");
            closeFiltre();
        }

        if(trigger.hasClass('triggered')) {
            var shad_filtre = $('#shad_link.filterOn');
            shad_filtre.on("click touch", function(){
                closeFiltre();
            });
        }
    }
}


function filterTriggerIn(event) {
    // class added on input when user filters
    $(event.target).toggleClass("is_checked");

}

function filterWriteLabel(event) {
    var wichFilter = $(event.target).text();
    $("#filter_word").text("");
    $("#filter_word").text(wichFilter);
}

function closeFiltre(){
    $('#filter_sticky').removeClass('triggered');
    $('#trigger_filtre').removeClass('show');
    $('#site_head_wrap').removeClass('filters_in');
    $('#shad_link').removeClass('actif filterOn');
    $( ".filtre_elem" ).each(function( index ) {
        $(this).removeClass("is_checked");
    });
}

function getValsFilterPriceRayRange(){
    var parent = this.parentNode;
    var slides = parent.getElementsByTagName("input");
    var slide1 = parseFloat( slides[0].value );
    var slide2 = parseFloat( slides[1].value );
    if( slide1 > slide2 ){ var tmp = slide2; slide2 = slide1; slide1 = tmp; }
    $("span.rangeValuesMin").text(slide1 + "€");
    $("span.rangeValuesMax").text(slide2 + "€");
}

$(window).on( "load", function() {
    // Initialize Sliders
    var sliderSections = document.getElementsByClassName("range-slider");
    for (var x = 0; x < sliderSections.length; x++) {
        var sliders = sliderSections[x].getElementsByTagName("input");
        for (var y = 0; y < sliders.length; y++) {
            if (sliders[y].type ==="range") {
                sliders[y].oninput = getValsFilterPriceRayRange;
                sliders[y].oninput();
            }
        }
    }
    getWishlistProducts();
});

/**
 * Retrieves the wishlist products from the DOM and sends an AJAX request to retrieve additional information.
 * 
 * @returns {void}
 */
function getWishlistProducts() {
    let array_elements = document.querySelectorAll('a[data-productid]');
    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');
            if (id != '' && id != 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_all_wishlist"),
            data: data,
            success: function (response) {
                if (response) {
                    if (typeof response != 'undefined' && response.length > 0) {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]')
                                .addClass("existToWishlistButton")
                                .attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id)
                            ;
                        }
                    }
                }
            }
        });
    }
}

function showpdv(is_find_store) {
    $('.btnFindRetailer').addClass('loading');
    var shad, modbox, contents;
    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_search_shop'),
        success: function (res) {
            if (res) {
                $('.btnFindRetailer').removeClass('loading');
                shad = document.getElementById('shad');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);
                $('#pdvBox').removeClass('deployed');

                // Make sure modbox will show on top of shad
                $(shad).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).html($('.storeloc_form', contents).add($('.liste_distributeur_wrapper', contents)));

                var listeMag = document.getElementById('liste_distributeur');
                listeMag.innerHTML = '';

                //wording
                if (is_find_store) {
                    //Ajout-suppression de classe sur le conteneur pour différencier les css entre : le trouver en magasin/Choisir magasin préféré
                    $('#pdvBox').addClass('find_art_in_store');
                    $('#pdvBox').removeClass('choose_fav_mag');
                } else {
                    $('#pdvBox').addClass('choose_fav_mag');
                    $('#pdvBox').removeClass('find_art_in_store');
                }

                // On va rajouter le filtre pour n'avoir que les magasin avec le comptoir pour les PDV
                // On doit le rajouter en JS car on utilise la même vue avec la recherche magasin
                if (!document.getElementById('filter_pdv')) {
                    var caratactestiques = [];

                    if (document.getElementById('produit_id')) {
                        var productId = document.getElementById('produit_id').value;
                    }
                    if (document.getElementById('produit_caracterstiques')) {
                        var is_caracteristique = document.getElementById('produit_caracterstiques');
                    }
                }

                // magasin_gmap.js
                initialize(true);

                $(modbox).addClass('actif');
                $(shad).addClass('actif');

                // Show modbox and make sure closing routine won't fire more than once
                $(shad).off('click', closepdv).fadeIn(200, function () {
                    $(modbox).slideDown(600);
                    $(shad).on('click', closepdv);
                    $('#content_pdvBox_new').hide();
                    $('#content_pdvBox').show();
                    $(modbox).removeClass('showForm');
                    $(modbox).removeClass('thanks');
                });
            } else {
                $('.btnFindRetailer').removeClass('loading');
            }
        }
    });
}

function closepdv() {
    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    if ($(".helloCustomer:visible").length) {
        $(modbox).removeClass('actif');
        $(shad).removeClass('actif');
        location.reload();
    } else {
        $(modbox).removeClass('actif');
        $(shad).removeClass('actif');
    }
}

$('body').on('mouseenter touchstart','#nrc_comments',function(){
    if($(".swiperNetreviews.swiper-container .swiper-slide").length) {
        size_list = new Swiper(".swiperNetreviews.swiper-container", {
            loop: false,
            slidesPerView: 2,
            spaceBetween: 10,
            navigation: {
                nextEl: '.nextSlideFp',
                prevEl: '.prevSlideFp',
            },
        });
    }
});

$(document).ready(function() {
    swiperBandeauCat();
} );

$(window).on("resize", function () {
    swiperBandeauCat();
});

function swiperBandeauCat() {
    var container = $('.selected_options.swiper-container');
    var swiperWrapper = $('.bandeau_menu_filter.swiper-wrapper');

    if ( swiperWrapper.width() > container.width() ) {
        // If images overflow the container, initialize Swiper
        new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            },
        });
    }
}

function createLineWoosmap(store, MapsProviderService){
    let div = '';
    let storeDetailsUrl = 'store_details.php?id=' + store.properties.store_id;
    let zipcodeAndCity = '';
    if (zipcode = store.properties.address.zipcode) {
        zipcodeAndCity = zipcode;
    }
    if (city = store.properties.address.city) {
        zipcodeAndCity += ' ' + this.formatAddress(city);
    }
    zipcodeAndCity = zipcodeAndCity.trim();
    if (MapsProviderService.device != null && MapsProviderService.device == 'mobile') {
        div = `<div class="bloc_marker_info">
                <p style="font-size:12px">
                    <span class="marker_store_name">${store.properties.name}</span>
                    <span class="marker_store_adr">${store.properties.address.lines.join('<br>')}</span>
                    <span class="marker_store_cp">${zipcodeAndCity}</span>
                </p>
                <div class="cta_marker_info">
                    <span class="learn_more"><a href="${storeDetailsUrl}">${Translator.translate('learn_more')}</a></span>
                    <a class="marker_go_map" href="">${Translator.translate('go_to_store')}</a>
            </div>`;
    } else {
        div = `<div class="info_popup">
                <div class="name"><a href="${storeDetailsUrl}">${store.properties.name}</a></div>
                <div class="adresse">${formatAddress(store.properties.address.lines.join('<br>'))}`;
        if (zipcodeAndCity.length > 0) {
            div += `<br>${zipcodeAndCity}`;
        }   
        if (store.properties.address.country_code) {
            let countryTranslationKey = `pays_${store.properties.address.country_code.toLowerCase()}`;
            div += `, ${Translator.translate(countryTranslationKey)}`;
        }

        if (store.properties.contact.phone) {
            div += `<br><a href="tel:${store.properties.contact.phone}">${store.properties.contact.phone}</a>`;
        }
        div += `</div>`;

        if (store.properties.opening_hours) {
            div += `<div class="horaire_info">`;

            // map the weekly opening hours to display them in the popup
            Object.entries(store.properties.weekly_opening).forEach(([dayOfWeek, hours]) => {
                if (typeof hours !== 'object') {
                    return;
                }
                let dayName = Translator.translate(`day_0${dayOfWeek}`);
                let isCurrentDay = new Date().getDay() == dayOfWeek;
                if (hours.hours.length == 0) {
                    div += `<span class="day_time ${isCurrentDay ? 'currently_open' : ''}">
                                <span class="day">${dayName} </span><span class="time">${Translator.translate('closed')}</span>
                            </span><br>`;
                    return;
                } else {                    
                    let dayHours = hours.hours.map(hour => `${hour.start} - ${hour.end}`).join(" " + Translator.translate('and_txt') + " ");
                    div += `<span class="day_time ${isCurrentDay ? 'currently_open' : ''}">
                        <span class="day">${dayName} </span><span class="time">${dayHours}</span>
                    </span><br>`;
                }
            });
            div += `</div>`;
        }       

        div += `<a class="savoir_plus" href="${storeDetailsUrl}">${Translator.translate('popup_shop_info')}</a>
        </div>`;
    }

    return div;
}
function formatAddress (address) {
    return address.charAt(0).toUpperCase() + address.slice(1).toLowerCase();
}

function elemGlobalShow() {
    $(".elem_global.delivery").show();
}